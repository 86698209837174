$font-family-sans-serif: "Open Sans", sans-serif;
$font-size-base: 14px;
$line-height-base: 1.5rem;

// colors
$text-color: #292b2c;
$background-color: #d2d0cb;
$background-light: #ecebe6;
$header-color: #7e7c77;
$white: #fff;
$black: #000000;
$light-grey: #f5f5f5;
$grey: #ececec;
$dark-grey: #4a4a4a;
$blue: #1470cc;
$orange: #cc7510;
$decommissioned: #cc3300;
$decommissioned-bg: #ffe6e6;
$red: #f50034;

// layout
$gutter: 2rem;
