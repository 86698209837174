.reset-to-frontpage--container {
  z-index: 10000;
  width: 100%;
  background-color: black;
  left: 0;
  position: fixed;
  top: 130px;
}

.reset-to-frontpage--text {
  background-color: black;
  color: white;
  padding: 20px;
  text-align: center;
  display: block;
  font-size: 2rem;
  line-height: 2.5rem;
  width: 400px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}
