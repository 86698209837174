* {
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}


// Body reset

html {
  position: relative;
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  width: 100%;
  height: 100%;

  @include tablet-and-up {
    height: 96%;
  }
}

body {
  font-family: $font-family-sans-serif;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $text-color;
  width: 100%;
  height: 100%;
  background-color: #eee;
  margin: 0;

  @include tablet-and-up {
    height: 96%;
  }
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
}

