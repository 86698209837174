.search-field--container {
  margin-bottom: 5rem;

  @include less-than-tablet {
    padding: 0 10px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  a {
    color: #fff;
    text-decoration: none;
    text-align: center;
    font-size: 2.1rem;
    font-family: "Open Sans", sans-serif;
    &:focus, &:hover {
      background-color: $blue;
      outline: solid 1px $blue;
    }
  }

  .search-field--title {
    font-family: "Open Sans", sans-serif;
    margin-bottom: 1rem;
    font-size: 1.8rem;
    font-weight: 600;
  }

  .search-field {
    height: 40px;
    background-color: transparent;
    font-family: Open Sans, sans-serif;
    font-size: 2rem;
    border: 2px solid $dark-grey;
    display: inline-block;
    width: 70%;
    max-width: 500px;
    line-height: 1.4;
    padding-left: 5px;
    padding-right: 5px;
    -webkit-appearance: none;

    &:focus {
      outline: solid 3px $blue;
    }

    @include phone {
      width: 60%;
    }

    @include tablet {
      width: 72%;
    }

    @include desktop {
      width: 72%;
    }
  }

  .search-field--spinner {
    position: absolute;
    margin-top: 10px;
    margin-left: -30px;
  }

  .search-field--button {
    background-color: $dark-grey;
    display: inline-block;
    justify-content: center;
    align-items: center;
    width: 20%;
    height: 40px;
    vertical-align: middle;
    padding-top: 11px;
    padding-right: 5px;
    margin-left: 5px;
    max-width: 150px;
    margin-bottom: 8px;

    @include phone {
      width: 30%;
      padding-top: 5px;
    }

    @include tablet {
      width: 25%;
      padding-top: 6px;
    }

    @include desktop {
      width: 25%;
      padding-top: 6px;
    }

    .search-field--button--image {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;

      img {
        height: 30px;

        @include small-phone {
          display: none;
        }
      }
    }

    .search-field--button--button {
      border: none;
      background: transparent;
      display: inline-block;
      @include small-phone {
        display: none;
      }
    }
    .search-field--button--text {
      vertical-align: middle;
      display: inline-block;
      font-size: 1.8rem;
    }
  }
}
