.container {
  @include less-than-tablet {
    .result-group:nth-child(odd) {
      background-color: #ececec;
    }

    .result-group:nth-child(even) {
      background-color: #f9f9f9;
    }
  }
}
.category-tiles {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .category-tile--container {
    box-sizing: border-box;
    flex: 1 256px;
    height: 200px;
    min-width: 256px;
    background-color: rgba(0, 0, 0, 0.5);
    background-size: cover;
    background-position: center;
    z-index: 1;
    position: relative;
    color: white;
    font-family: "Open Sans", sans-serif;
    margin: 5px;
    @include less-than-tablet {
      margin: 5px 10px;
    }
    .category-tile--gradient {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }

    &:after {
      z-index: 2;
      background-color: darkgray;
    }

    .category-tile--label {
      display: block;
      font-size: 16pt;
      text-shadow: 1px 1px #000;
      margin: 0.6em 0.6em 1em 0.6em;
      line-height: 1.3em;
      font-weight: 100;
    }

    .category-tile--index {
      background-color: $blue;
      padding: 2px 5px;
    }

    .category-tile--text-container {
      text-align: center;
      vertical-align: middle;
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translate(0, -50%);
    }
    &:hover,
    &:focus {
      outline: solid 5px $blue;
    }
  }
}

.category-tiles--title {
  h2 {
    padding: 0 10px 10px 0;

    @include less-than-tablet {
      padding-left: 10px;
    }
  }
}

.result-list {
  list-style-type: none;
  li {
    display: flex;
  }

  li * {
    display: inline-block;
    font-size: 1.7rem;
  }

  li:before {
    font-size: 2rem;
    content: "-";
    margin-right: 1rem;
  }
}

.result-element {
  h2 {
    .result-element-link {
      font-weight: 400;
    }
  }

  &.decommissioned {
    background-color: $decommissioned-bg;
  }
}

.result-element-title {
}

.result-element-link {
  font-weight: normal;
  margin-right: 0.5rem;
}

.result-element-note {
  display: inline;
  font-size: 1.6rem;
  font-weight: normal;
  margin-right: 0.5rem;
  &:before {
    content: "– ";
  }
}

.spelling-error {
  margin: 5px;
  margin-bottom: 15px;
  padding: 5px;
  border: 2px solid $red;

  .spelling-error--correction {
    color: blue;
  }
}

.spelling-suggestion {
  font-size: 20pt;
  line-height: 1em;

  &:before {
    content: "●";
  }
}

.search-result--messages {
  text-align: center;
  padding-bottom: 20px;
}

.search-result--spinner {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

ul.result-list {
  padding-left: 1rem;
  margin-top: 0;
  margin-bottom: 0;
}

.result-group {
  padding: 10px;

  @include tablet-and-up {
    padding: 0;
  }

  h2 {
    @include less-than-tablet {
      font-weight: 600;
    }
  }
}
