.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-family: inherit;
  font-weight: 600;
  line-height: 1.1;
  color: inherit;
}

h1, .h1 {
  font-size: 2.1rem;

  @include small-phone {
    font-weight: 400;
    font-size: 1.9rem;
    margin-bottom: 0;
  }

  @include phone {
    font-weight: 400;
    font-size: 1.9rem;
    margin-bottom: 0;
  }
}

h2, .h2 {
  font-size: 2rem;

  @include small-phone {
    font-weight: 400;
    font-size: 1.8rem;
    margin-bottom: 0;
  }

  @include phone {
    font-weight: 400;
    font-size: 1.8rem;
    margin-bottom: 0;
  }
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

a, .link {
  cursor: pointer;
  color: $blue;
  text-decoration: underline;
  &.small {
    text-transform: uppercase;
    font-size: 10px;
  }
}
