$navbar_height: 60px;

.pro {
  .container {
    @include transition-width();
    overflow-x: hidden;
    display: inline-block;
    &.show-cart {
      width: 60%;
      @include less-than-desktop {
        width: 100%;
      }
    }
  }

  @include less-than-tablet {
    .top-bar--cart {
      margin-left: auto;
    }
    .top-bar--link {
      margin-left: 15px !important;
    }
  }
}

.pro .has-comparer .container {
  height: calc(100vh - 120px);
  overflow-y: auto;
}

.pro {
  .hierarchy {
    width: 100%;
    max-width: 1024px;
  }
}
.hierarchy {
  a {
    text-decoration: none;
  }

  .name {
    color: $black;
    font-size: 2rem;
    margin-bottom: 0;
    font-weight: 100;
  }
  .dk5 {
    font-size: 2rem;
    font-weight: 100;
  }

  @include less-than-tablet {
    .name, .dk5 {
      font-size: 1.8rem;
    }

    .hierarchy-level:nth-child(odd) {
      background-color: $white;
    }

    .hierarchy-level:nth-child(even) {
      background-color: $grey;
    }

    &.container {
      border-top: 1px solid #e0e0e0;
    }
  }
}
.hierarchy-row-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 1rem;
  @include less-than-tablet {
    flex-direction: row-reverse;
    padding-left: 15px;
  }
  .hierarchy-row-right-elements {
    display: flex;
    align-items: center;
    @include less-than-tablet {
      flex-direction: row-reverse;
      width: 100%;
    }
  }

  img {
    width: 20px;
    margin-right: 1rem;
    @include less-than-tablet {
      display: none;
    }
  }
  .dk5 {
    background-color: $blue;
    color: $white;
    padding: 0.5rem;
  }

  &:focus {
    outline: none;
    .name {
      outline: 2px solid $orange;
      outline-offset: 5px;
    }
  }
}

.hierarchy-level--header {
  .hierarchy-row-container {
    padding-left: 1rem;

    h1 {
      font-size: 2.1rem;
    }
    @include less-than-tablet {
      h1 {
        font-size: 1.8rem;
      }
      padding-left: 5px;
    }
    .dk5 {
      margin-bottom: 0;
      color: $blue;
    }

    &:focus {
      .name {
        outline-color: $white;
      }
    }
  }
}

.hierarchy-display {
  height: inherit;
}

.hierarchy-level {
  margin: 1rem 0;

  @include less-than-tablet {
    margin: 0;
  }
  .with-cart-button {
    display: flex;
    align-items: center;
  }

  .selected {
    transition: background 100ms ease-out;
    background-color: $light-grey;
    color: black;
    .hierarchy-level .level div {
      a {
        h1 {
          font-size: 2rem;
          @include less-than-tablet {
            font-size: 1.8rem;
          }
        }
      }
      .name {
        color: $black;
      }

      padding: 0 1rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      @include less-than-tablet {
        width: 100%;
        padding-right: 0;
      }
    }

    .dk5 {
      color: $white;
      background-color: $blue;
      padding: 2px 5px;
      @include less-than-tablet {
        color: $blue;
        background-color: unset;
      }
    }

    .hierarchy-level--header {
      padding: 10px 10px;
      background-color: $blue;

      .name {
        color: $white;
      }

      .dk5 {
        color: $blue;
        background-color: $white;
        padding: 2px 5px;
        @include less-than-tablet {
          color: $white;
          background-color: unset;
        }
      }
    }

    .hierarchy-row-container {
      img {
        margin-right: 0;
      }
    }
    @include less-than-tablet {
      transition: none;
      margin: 0;
      padding: 0;
      color: black;

      position: fixed;
      z-index: 1000;
      top: $navbar_height;
      left: 0;
      height: calc(100% - 60px);
      width: 100%;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      &.level {
        .hierarchy-level--header {
          background-color: $blue;
          margin-bottom: 0;
          padding: 2.5rem 1rem;
          .hierarchy-row-container {
            .name,
            .dk5 {
              color: white;
            }
          }
        }
      }
    }

    svg {
      @include less-than-tablet {
        fill: black;
      }
    }
    .name {
      color: white;

      @include less-than-tablet {
        color: black;
      }
    }
  }

  .dk5 {
    display: inline-block;
    margin-right: 10px;
  }

  .name {
    margin-right: 5px;
  }

  .decommissioned {
    background-color: $orange !important;
    color: white !important;
  }
  .hasChildren {
    &:after {
      margin-left: 2px;
      font-size: 75%;
      content: "+";
    }
  }
}

.hierarchy--navbar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  height: $navbar_height;
  width: 100%;
  z-index: 10;
  border-bottom: 1px solid #dedede;
  padding-top: 21px;

  .hierarchy--navbar--href {
    display: flex;
    align-content: center;
    padding-left: 15px;
    align-items: center;

    a {
      width: 90%;
    }
    .hierarchy--navbar--text {
      font-weight: 500;
      font-size: 1.6rem;
      color: $black;
      bottom: 6px;
      margin-left: 4px;
    }
  }

  @include less-than-tablet {
    display: block;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 0px;
  }
}

.hierarchy--navbar--button {
  display: inline-block;
  font-size: 4rem;
  margin: 0 10px;
  vertical-align: middle;
}

.hierarchy--navbar--title {
  font-size: 2.5rem;
  display: inline-block;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  text-align: center;
}

.hierarchy--navbar--cart {
  float: right;

  .top-bar--cart {
    margin-left: 0;
    margin-right: 10px;
  }
}

.hierarchy-topics {
  padding-left: 0;
  margin-bottom: 3rem;

  li {
    margin-bottom: 1rem;
    list-style-type: none;
    padding-left: 2rem;

    @include less-than-tablet {
      padding-left: 0;
    }

    &:before {
      display: inline-block;
      content: "-";
      text-indent: -5px;
    }

    .note {
      display: inline-table;
      margin-left: 1rem;
      text-indent: -0.8rem;

      a {
        text-decoration: underline;
        @include less-than-tablet {
          color: black;
        }
      }
    }
    &:focus {
      a {
        color: red;
        background-color: blue;
      }
    }
  }
}

.hierarchy-description {
  @include less-than-tablet {
    display: none;
  }

  .content {
    line-height: 1.4;
    margin-bottom: 3rem;
  }
  .content a {
    color: white;
    text-decoration: underline;

    @include less-than-tablet {
      color: black;
    }
  }
}

.level-1 {
  @include less-than-tablet {
    padding: 15px 10px;

    .name {
      display: inline-block;
      width: 78%;
      margin-right: 0;
      padding-right: 5px;
    }

    .dk5 {
      float: right;
      display: inline-block;
      width: 18%;
      text-align: right;
    }
  }

  @include less-than-tablet {
    padding: 10px;
  }
}

.level-2 {
  margin-left: 1rem;

  @include less-than-tablet {
    padding: 15px 10px;
    margin-left: 0;

    .name {
      display: inline-block;
      width: 84%;
      margin-right: 0;
      padding-right: 5px;
    }

    .dk5 {
      float: right;
      display: inline-block;
      width: 16%;
    }
  }

  h2 {
    font-size: 2rem;

    @include less-than-tablet {
      font-size: 1.8rem !important;
      margin-bottom: 0;
    }
  }
  .rel {
    .hierarchy-level--header,
    .hierarchy-level-non-header {
      padding-left: 3rem;
    }
  }

  .hierarchy-level--header {
    .hierarchy-row-container {
      padding-left: 1rem;
    }
  }
}

.level-3 {
  margin-left: 2rem;

  @include less-than-tablet {
    padding: 15px 10px;
    margin-left: 0;

    .name {
      display: inline-block;
      width: 68%;
      margin-right: 0;
      padding-right: 5px;
    }

    .dk5 {
      float: right;
      display: inline-block;
      width: 32%;
    }
  }

  h2 {
    font-size: 1.8rem;

    @include less-than-tablet {
      font-size: 1.6rem;
      margin-bottom: 0;
    }
  }
  .rel {
    .hierarchy-level--header,
    .hierarchy-level-non-header {
      padding-left: 3rem;
    }
  }
}

.level-4 {
  margin-left: 3rem;

  @include less-than-tablet {
    padding: 15px 10px;
    margin-left: 0;
    .name {
      display: inline-block;
      width: 68%;
      margin-right: 0;
      padding-right: 5px;
    }

    .dk5 {
      float: right;
      display: inline-block;
      width: 32%;
    }
  }

  h2 {
    font-size: 1.6rem;

    @include less-than-tablet {
      margin-bottom: 0;
      font-weight: 400;
    }
  }
}

.level-5 {
  margin-left: 4rem;

  @include less-than-tablet {
    padding: 15px 10px;

    .name {
      display: inline-block;
      width: 68%;
      margin-right: 0;
      padding-right: 5px;
    }

    .dk5 {
      float: right;
      display: inline-block;
      width: 32%;
    }
  }

  h2 {
    font-size: 1.4rem;

    @include less-than-tablet {
      margin-bottom: 0;
      font-weight: 400;
    }
  }
}

.level-6 {
  margin-left: 4rem;

  @include less-than-tablet {
    padding: 15px 10px;

    .name {
      display: inline-block;
      width: 68%;
      margin-right: 0;
      padding-right: 5px;
    }

    .dk5 {
      float: right;
      display: inline-block;
      width: 32%;
    }
  }

  h2 {
    font-size: 1.4rem;

    @include less-than-tablet {
      margin-bottom: 0;
      font-weight: 400;
    }
  }
}

.title-note {
  display: inline-block;
  vertical-align: middle;
  padding-left: 1rem;
  a {
    text-decoration: underline;

    @include less-than-tablet {
      color: $blue;
      font-size: 1.6rem;
    }
    &:focus {
      outline: 2px solid $orange;
      outline-offset: 5px;
    }
  }
}

.hierarchy-spinner {
  display: inline-block;
  vertical-align: middle;
  margin-left: 7px;
}

.hierarchy-el {
  padding: 2rem 5rem;

  @include less-than-tablet {
    padding: 2rem 3.5rem;
    .toggle-wrapper {
      margin-bottom: 10px;
    }
  }
  .toggle-button {
    margin-left: 2rem;
    &:focus {
      outline-color: $orange;
    }
    @include less-than-tablet {
      color: $blue;
      border-bottom: none;
      margin-left: 1rem;

      .icon,
      .svg {
        fill: $blue;
      }
    }
  }
}

.historic-note,
.systematic-note {
  padding-bottom: 0.55em;

  a {
    text-decoration: underline;
  }
}
