.flex {
  display: flex;
}
.grow {
  flex-grow: 1;
}

.baseline {
  align-items: baseline;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
  &.right {
    top: 0;
    right: 0;
  }
}

.bold {
  font-weight: 700;
}

.italic {
  font-style: italic;
}

.is-hidden    { display:    none !important; }
.is-invisible { visibility: hidden !important; }

.block        { display: block        !important; }
.block-center {
  display: block        !important;
  margin: auto;
}
.inline       { display: inline       !important; }
.inline-block { display: inline-block !important; }

.left  { float: left  !important; }
.right { float: right !important; }

.text-left   { text-align: left   !important; }
.text-center { text-align: center !important; }
.text-right  { text-align: right  !important; }

.mt0 { margin-top:    0   !important; }
.mt1 { margin-top:    gutter() !important; }
.mb0 { margin-bottom: 0   !important; }
.mb1 { margin-bottom: gutter() !important; }
.mb2 { margin-bottom: gutter(2) !important; }
.mb4 { margin-bottom: gutter(4) !important; }

.color-light {
  color: $header-color;
}

.pointer { cursor: pointer; }

.blue {
  color: $blue;
}
