.top-bar--container,
.top-bar--logo {
  height: 35px;
}
.hide-navbar {
  display: none;
}
.top-bar--container {
  display: flex;
  align-items: center;
  height: 60px;
  margin-bottom: 3rem;
  @include less-than-tablet {
    padding: 1rem;
    margin-bottom: 0rem;
  }
  a {
    display: flex;
    height: 40px;
  }

  .top-bar--logo {
    margin: 0 10px 0 0;
    width: 35px;
  }

  .top-bar--link {
    text-align: right;
    margin-left: auto;
    float: right;
  }

  .top-bar--question {
    height: 30px;
  }

  .top-bar--caption {
    color: #1470cc;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: normal;

    @include small-phone {
      font-size: 1.9rem;
    }

    @include phone {
      font-size: 2.2rem;
    }
  }
}

.top-bar--cart {
  margin-left: 20px;

  img {
    width: 25px;
    height: 25px;
  }
}

.top-bar--cart--count {
  position: absolute;
  margin-left: -28px;
  margin-top: -6px;
  text-align: center;
  background: brown;
  color: #ffffff;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  font-size: 0.9rem;
  padding-top: 1px;
}

.top-bar--cart--pointer {
  cursor: pointer;
}
