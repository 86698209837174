// Import fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700');

/* Config */
@import 'config/config';

/* Includes */
@import 'includes/buttons';
@import 'includes/flex';
@import 'includes/form-group';
@import 'includes/gutter';
@import 'includes/spacer';
@import 'includes/spinner';
@import 'includes/transitions';
@import 'includes/media-quires';

/* Globals */
@import 'globals/resets';
@import 'globals/typography';
@import 'globals/forms';
@import 'globals/globals';
@import 'globals/icon';

// Import component styles
@import 'components/CartButton.component';
@import 'components/ResetToFrontpage.component';
@import 'components/ComparerContainer.component';
@import 'components/AboutContainer.component';
@import 'components/HelpContainer.component';
@import 'components/Hierarchy';
@import 'components/TopBar.component';
@import 'components/SearchResultsContainer.component';
@import 'components/SearchField.component';
@import 'components/Suggestions.component';

@import 'utils/utils';

// Set layout
.main {
  max-width: 1024px;
  margin: 0 auto;
  height: 100%;

  &.pro {
    @include tablet-and-up {
      height: 100%;
    }

    @include xldesktop {
      max-width: 1600px;
    }
  }
}

#content {
  background-color: white;
  padding: 3rem;
  padding-top: 1rem;

  @include small-phone {
    margin-top: 0;
    padding: 0;
  }

  @include phone {
    margin-top: 0;
    padding: 0;
  }
}

.root-container {
  height: 100%;
}

.footer {
  text-align: center;
  width: 100%;
  margin-top: 50px;
  font-size: 1rem;
  color: #292b2c;

  a  {
    text-decoration: none;
    color: #292b2c;
  }

  a:hover {
    text-decoration: underline;
  }

  a.link {
    text-decoration: underline;
  }
}
