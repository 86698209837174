.cart-button-container {
  @include tablet-and-up {
    display: inline-block;
  }
  height: 20px;
  margin-right: -1rem;
  @include less-than-tablet {
    width: auto !important;
  }
}

.add-or-remove-item {
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;

  @include less-than-tablet {
    margin-top: 10px;
    margin-right: 0;
  }

  svg {
    transition: transform 0.25s ease-out;
    width: 16px;
    height: 16px;
  }

  &.in-cart {
    svg {
      transform: rotate(135deg);
    }
  }
}

.pro {
  @include less-than-tablet {
    .add-or-remove-item {
      margin-top: 0px;
    }

    .hierarchy-level--header {
      .add-or-remove-item {
        margin-right: 1rem;
      }
    }
  }
}
