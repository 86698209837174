.icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  &.white svg {
    fill: $white;
    stroke: $white;
  }
  img, svg {
    width: 100%;
    height: 100%;
  }
  .arrow {
    position: relative;
    bottom: -4px;
  }
}