// breakpoints
$phone: 400px;
$tablet: 600px;
$desktop: 800px;
$xl-desktop: 1000px;


@mixin small-phone {
  @media (max-width: $phone - 1px) {
    @content;
  }
}

@mixin phone {
  @media (min-width: $phone) and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin less-than-tablet {
  @media (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin tablet-and-up {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin less-than-desktop{
  @media (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin xldesktop {
  @media (min-width: $xl-desktop) {
    @content;
  }
}
